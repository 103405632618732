// Este comentario me imagino que es un error xq si esta en las dependencias
// import { InView } from 'react-intersection-observer'
import React, { useState, useEffect } from 'react'
import { Button } from '@mui/material'
import ExpandMoreRounded from '@mui/icons-material/ExpandMoreRounded'

import styles from './discountsHolder.module.css'
import DiscountCard from './DiscountCard'
import { Discount } from '../../../types/discount'
import { neutralWhite } from '../../../style'
import { logSMEvent } from '../../../events/logEvent'
import { MAINTAGV3Enum } from '../../../types/mainTagV3'

const { discountAndDescriptionFrame, discountsHolder, finishDot } = styles

const DISCOUNTS_TO_RENDER_EACH_LOADING = 24 // (3x4) so it can display nice in any screen

const DiscountsHolder = function ({
  discountsToShow,
  category,
  isStar,
}: {
  discountsToShow: Discount[]
  category: MAINTAGV3Enum
  isStar: boolean
}) {
  const [amountDiscountsToRender, setAmountDiscountsToRender] = useState(
    DISCOUNTS_TO_RENDER_EACH_LOADING
  )
  // const initialTrackerAndLoadingValue = discountsToShow?.length > DISCOUNTS_TO_RENDER_EACH_LOADING
  // const [showTracker, setShowTracker] = useState(initialTrackerAndLoadingValue)
  // const [showLoading, setShowLoading] = useState(initialTrackerAndLoadingValue)

  // const handleVisibility = (isVisible: boolean) => {
  //   if (isVisible && showTracker) {
  //     // revisar si showTracker = true no es necesario pero lo dejamos igual
  //     setShowTracker(false)
  //     setAmountDiscountsToRender(amountDiscountsToRender + DISCOUNTS_TO_RENDER_EACH_LOADING)
  //     setTimeout(() => {
  //       // esperamos un ratito antes de mostrar el nuevo tracker
  //       if (amountDiscountsToRender < discountsToShow?.length) {
  //         setShowTracker(true)
  //       }
  //     }, 1500)
  //     clearTimeout(0)
  //   }
  // }

  useEffect(() => {
    // en caso de que cambien los filtros, es necesario resetear los estados
    // setShowTracker(initialTrackerAndLoadingValue)
    // setShowLoading(initialTrackerAndLoadingValue)
    setAmountDiscountsToRender(DISCOUNTS_TO_RENDER_EACH_LOADING)
  }, [discountsToShow])

  return (
    <div className={discountAndDescriptionFrame}>
      <div className={discountsHolder}>
        {discountsToShow?.slice(0, amountDiscountsToRender).map((discount) => {
          return <DiscountCard discount={discount} isStar={isStar} key={discount.id} />
        })}
      </div>
      {/* {showTracker && (
        <InView onChange={handleVisibility} threshold={0.5}>
          <CircularProgress style={{ marginBottom: '100px', marginTop: '20px' }} />
        </InView>
      )} */}
      {amountDiscountsToRender >= discountsToShow?.length ? (
        <div className={finishDot} />
      ) : (
        <Button
          onClick={() => {
            logSMEvent('VER_MAS_DESCUENTOS', {
              category,
            })
            setAmountDiscountsToRender(amountDiscountsToRender + DISCOUNTS_TO_RENDER_EACH_LOADING)
          }}
          sx={{
            borderRadius: 40,
            padding: '4px 16px',
            margin: '20px 0',
            display: 'flex',
            backgroundColor: neutralWhite,
            flex: 1,
            boxShadow: '2px 2px 4px rgba(70,70,70,0.25)',
            alignItems: 'center',
            textTransform: 'none',
          }}
        >
          Ver más
          <ExpandMoreRounded />
        </Button>
      )}
    </div>
  )
}

export default DiscountsHolder
