import React, { useContext, useState } from 'react'

import TransferWithinAStationRoundedIcon from '@mui/icons-material/TransferWithinAStationRounded'
import CalendarTodayRoundedIcon from '@mui/icons-material/CalendarTodayRounded'
import CampaignRoundedIcon from '@mui/icons-material/CampaignRounded'
import BookmarkBorderRoundedIcon from '@mui/icons-material/BookmarkBorderRounded'
import BookmarkRoundedIcon from '@mui/icons-material/BookmarkRounded'

import styles from './discountCard.module.css'
import { showTwoDecimals } from '../../../helpers/location'
import { normalizeDaysToText } from '../../../helpers/others'
import { neutral50White, primaryPrimary, secondary40White } from '../../../style'
import { request } from '../../../helpers/request'
import { AppContext, UserAuthType } from '../../../contexts/AppContext'
import { logSMEvent } from '../../../events/logEvent'
import { Discount } from '../../../types/discount'
import { SMHorizontalDiv } from '../../../SMComponents/SMView'
import { SMIconButton, SMPrimaryButton } from '../../../SMComponents/SMButton'
import { DiscountContext } from '../../../contexts/DiscountContext'
import {
  RESERVE_FIELDS_REQUIRED,
  SAVE_DISCOUNTS_FIELDS_REQUIRED,
  isAuthAllowedToReserved,
  isAuthAllowedToSaveDiscounts,
} from '../../../consts/loginRestrictions'
import { SMH3, SMP } from '../../../SMComponents/SMText'
import { StarOptions } from '../../admin/components/StarOptions'
import { IS_PROD_ENV } from '../../../consts/env'
import { MAINTAGV3Enum } from '../../../types/mainTagV3'
import { ClubEnums } from '../../../types/clubs'

const {
  discountCard,
  discountHeaderFrame,
  backgroundImage,
  discountBodyFrame,
  allConditionsFrame,
  gMapPhotoInSlider,
} = styles

const DiscountCard = function ({ discount, isStar }: { discount: Discount; isStar: boolean }) {
  // escribe discount.XClosestLocation y discount.XGoogleMapsLink
  const { auth, setAuth, handleOpenSnackBar, clubs } = useContext(AppContext)
  const { handleOpenLoginDrawer, handleOpenReservationDrawer, handleDiscountClick } =
    useContext(DiscountContext)
  const [isSaved, setIsSaved] = useState<boolean>(
    auth?.savedDiscounts?.includes(discount.id) || false
  )

  const showDistance =
    discount.XClosestLocation && (discount.XClosestLocation.distance < 10 || !IS_PROD_ENV)

  const onDiscountCardClicked = () => {
    logSMEvent('DISCOUNT_CARD_CLICKED', {
      id: discount.id,
      url: discount.url,
      mainTagV3: discount.mainTagV3,
      club: discount.club,
    })
    handleDiscountClick(discount)
  }

  const handleReservation = () => {
    const hasToCompleteLoginFields = !isAuthAllowedToReserved(auth)
    logSMEvent('RESERVATION_BUTTON_CLICKED_0', {
      id: discount.id,
      url: discount.url,
      hasToCompleteLoginFields,
      phoneNumber: auth?.phoneNumber,
    })
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const handleAction = (userAuth: UserAuthType) => {
      handleOpenReservationDrawer({
        source: 'discount_card',
        discount,
        hasUserJustLoggedIn: hasToCompleteLoginFields, // no usamos userAuth, xq ese es el nuevo post login
      })
    }
    if (!auth || hasToCompleteLoginFields) {
      handleOpenLoginDrawer({
        source: 'reservation_button',
        title: 'Completa tu perfil para reservar una mesa.',
        subtitle:
          'Además de reservar una mesa, podrás acceder a guardar descuentos, ver beneficios exclusivos y más.',
        fields: RESERVE_FIELDS_REQUIRED,
        buttonLabel: 'Reservar mesa',
        onSubmit: handleAction,
      })
    } else {
      handleAction(auth)
    }
  }

  const handleSaveDiscount = () => {
    const handleAction = async (userAuth: UserAuthType) => {
      if (!isSaved) {
        logSMEvent('DISCOUNT_SAVED', {
          id: discount.id,
          url: discount.url,
          mainTagV3: discount.mainTagV3,
          club: discount.club,
        })
      }
      setIsSaved(!isSaved)
      handleOpenSnackBar({
        message: isSaved ? 'Descuento retirado' : 'Descuento guardado ',
      })
      const response = await request(
        'save_discount',
        {
          method: 'POST',
          body: JSON.stringify({
            discountId: discount.id,
            save: !isSaved,
          }),
        },
        userAuth
      )
      const newListOfDiscounts = (response?.data?.savedDiscounts as string[]) || null
      setAuth({
        ...userAuth,
        savedDiscounts: newListOfDiscounts ? newListOfDiscounts : userAuth.savedDiscounts,
      })
    }
    if (!auth || !isAuthAllowedToSaveDiscounts(auth)) {
      handleOpenLoginDrawer({
        source: 'like_button',
        title: 'Completa tu perfil para guardar descuentos',
        subtitle:
          'Podrás guardar descuentos, reservar mesas, acceder a beneficios exclusivos y más.',
        fields: SAVE_DISCOUNTS_FIELDS_REQUIRED,
        buttonLabel: 'Guardar descuento',
        onSubmit: handleAction,
      })
    } else {
      handleAction(auth)
    }
  }

  return (
    <div
      className={discountCard}
      key={discount.id}
      onClick={onDiscountCardClicked}
      style={{
        cursor: 'pointer',
      }}
    >
      <div className={discountHeaderFrame}>
        <img // background para que se vea lindo atras de la imagen
          className={backgroundImage}
          src={discount.bgimage}
          alt="."
          referrerPolicy="no-referrer"
        />
        {discount.logo && discount.logo !== discount.bgimage && (
          <img
            alt="."
            src={discount.logo}
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            }}
          />
        )}
        <img
          style={{
            marginRight: discount?.gMapsResults ? '2px' : '0px',
            width: '100%',
            objectFit:
              discount.club == ClubEnums.CLARO || discount.club == ClubEnums.LIDERBCI
                ? 'contain'
                : 'cover',
          }}
          src={discount.bgimage}
          alt="."
          referrerPolicy="no-referrer"
        />
        {discount?.gMapsResults
          ?.map((r) => r.photosUrls)
          ?.flat()
          ?.slice(0, 10)
          ?.map((url, index) => {
            return <img key={index} src={url} alt="." className={gMapPhotoInSlider} />
          })}
      </div>
      <div className={discountBodyFrame}>
        <SMHorizontalDiv>
          {discount.club && clubs ? (
            <img
              src={clubs[discount.club]?.img}
              style={{ maxWidth: 30, maxHeight: 20, objectFit: 'contain' }}
              alt={discount.club}
            />
          ) : null}
          <SMH3 style={{ color: primaryPrimary }}>{discount.titulo}</SMH3>
        </SMHorizontalDiv>
        {showDistance && discount.XClosestLocation?.distance !== undefined && (
          <div style={{ display: 'flex', marginBottom: '5px' }}>
            <TransferWithinAStationRoundedIcon sx={{ marginRight: '10px' }} />
            <p style={{ display: 'flex', paddingTop: '7px', alignItems: 'center' }}>
              a {showTwoDecimals(discount.XClosestLocation.distance)} km aprox.
            </p>
          </div>
        )}
        {isStar && <StarOptions discount={discount} />}

        <SMHorizontalDiv maxSpaceBetween>
          <div className={allConditionsFrame}>
            <div style={{ display: 'flex', gap: 8 }}>
              <CalendarTodayRoundedIcon fontSize="small" htmlColor={neutral50White} />
              <SMP>Días: {normalizeDaysToText(discount.diasNormalizados)}</SMP>
            </div>
            {discount.comments?.length && (
              <div style={{ display: 'flex', gap: 8 }}>
                <CampaignRoundedIcon fontSize="small" htmlColor={neutral50White} />
                <SMP>
                  {discount.comments.length} Comentario{discount.comments.length > 1 ? 's' : ''}{' '}
                  reportado{discount.comments.length > 1 ? 's' : ''}
                </SMP>
              </div>
            )}
          </div>
          <SMHorizontalDiv>
            <SMIconButton onClick={handleSaveDiscount}>
              {isSaved ? (
                <BookmarkRoundedIcon sx={{ color: secondary40White }} />
              ) : (
                <BookmarkBorderRoundedIcon sx={{ color: secondary40White }} />
              )}
            </SMIconButton>
          </SMHorizontalDiv>
        </SMHorizontalDiv>
        {discount.isReservable &&
          discount.reservationLink &&
          discount.mainTagV3?.includes(MAINTAGV3Enum.RESTOBAR_PRESENCIAL) && (
            <SMPrimaryButton
              fullWidth
              small
              sx={{ margin: '4px 0px' }}
              onClick={(e: any) => {
                e.preventDefault() // prevent the default action of the browser
                e.stopPropagation() // prevent the click from propagating to the parent
                handleReservation()
              }}
            >
              Reservar mesa
            </SMPrimaryButton>
          )}

        {isStar && (
          <>
            <SMP small sx={{ textAlign: '' }}>
              AI actualización:{' '}
              {new Intl.DateTimeFormat('es-ES', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                hour12: true,
              }).format(new Date((discount?.aiQueryOutputTime?._seconds | 0) * 1000))}
            </SMP>
            <SMP small sx={{ textAlign: '' }}>
              GMaps actualización:{' '}
              {new Intl.DateTimeFormat('es-ES', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                hour12: true,
              }).format(new Date((discount?.gMapsOutputTime?._seconds | 0) * 1000))}
            </SMP>
          </>
        )}
      </div>
    </div>
  )
}

export default DiscountCard
