import React, { useContext, useState } from 'react'

import CalendarTodayRoundedIcon from '@mui/icons-material/CalendarTodayRounded'
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded'
import OpenInNewRoundedIcon from '@mui/icons-material/OpenInNewRounded'
import CreditCardRoundedIcon from '@mui/icons-material/CreditCardRounded'
import SportsBarRoundedIcon from '@mui/icons-material/SportsBarRounded'
import LunchDiningRoundedIcon from '@mui/icons-material/LunchDiningRounded'
import PanToolRoundedIcon from '@mui/icons-material/PanToolRounded'
import BrunchDiningRoundedIcon from '@mui/icons-material/BrunchDiningRounded'
import CoffeeRoundedIcon from '@mui/icons-material/CoffeeRounded'
import DinnerDiningRoundedIcon from '@mui/icons-material/DinnerDiningRounded'
import RamenDiningRoundedIcon from '@mui/icons-material/RamenDiningRounded'
import LocalPizzaRoundedIcon from '@mui/icons-material/LocalPizzaRounded'
import IcecreamRoundedIcon from '@mui/icons-material/IcecreamRounded'
import RestaurantRoundedIcon from '@mui/icons-material/RestaurantRounded'
import FastfoodRoundedIcon from '@mui/icons-material/FastfoodRounded'
import SoupKitchenIcon from '@mui/icons-material/SoupKitchen'
import SetMealRoundedIcon from '@mui/icons-material/SetMealRounded'
import BakeryDiningRoundedIcon from '@mui/icons-material/BakeryDiningRounded'
import ShareRoundedIcon from '@mui/icons-material/ShareRounded'
import CampaignRoundedIcon from '@mui/icons-material/CampaignRounded'
import Divider from '@mui/material/Divider'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'

import { AppContext } from '../../../contexts/AppContext'
import { logSMEvent } from '../../../events/logEvent'
import {
  neutral50White,
  neutralBlack,
  neutralWhite,
  primary90White,
  primaryPrimary,
  secondary40White,
} from '../../../style'
import { addUtmParamsToUrl, normalizeDaysToText } from '../../../helpers/others'
import { ShareButton } from '../../common/SMButton'
import { Rating } from './map/DiscountMapCard'
import { DiscountContext } from '../../../contexts/DiscountContext'
import { Drawer } from '@mui/material'
import { Discount } from '../../../types/discount'
import { capitalizeFirstChar } from '../../../helpers/normalizeText'
import { SMPrimaryButton } from '../../../SMComponents/SMButton'
import { SMH3, SMP } from '../../../SMComponents/SMText'
import discountCardStyle from './discountCard.module.css'
import { SMChip } from '../../../SMComponents/SMChip'
import { SMHorizontalDiv, SMVerticalDiv } from '../../../SMComponents/SMView'
import { formatPrice } from '../../../helpers/formatPrice'
import { StarOptions } from '../../admin/components/StarOptions'
import { DiscountChildrenFrame } from './DiscountChildrenFrame'

const googleFoodAndDrinkTypesToMUIIcons: {
  [key: string]: React.ReactElement | null
} = {
  american_restaurant: null,
  bar: <SportsBarRoundedIcon />,
  brunch_restaurant: <BrunchDiningRoundedIcon />,
  restaurant: <RestaurantRoundedIcon />,
  food: null,
  point_of_interest: null,
  establishment: null,
  event_venue: null,
  coffee_shop: <CoffeeRoundedIcon />,
  cafe: null,
  store: null,
  italian_restaurant: <DinnerDiningRoundedIcon />,
  japanese_restaurant: <RamenDiningRoundedIcon />,
  pizza_restaurant: <LocalPizzaRoundedIcon />,
  ice_cream_shop: <IcecreamRoundedIcon />,
  fast_food_restaurant: <FastfoodRoundedIcon />,
  indian_restaurant: <SoupKitchenIcon />,
  hamburger_restaurant: <LunchDiningRoundedIcon />,
  sushi_restaurant: <SetMealRoundedIcon />,
  breakfast_restaurant: <BakeryDiningRoundedIcon />,
  vegetarian_restaurant: null,
  vietnamese_restaurant: null,
  chinese_restaurant: null,
  mexican_restaurant: null,
  vegan_restaurant: null,
  bed_and_breakfast: null,
  child_care_agency: null,
  korean_restaurant: null,
  thai_restaurant: null,
}

const { discountHeaderFrame, backgroundImage, gMapPhotoInSlider, closeCardButton } =
  discountCardStyle

export const DiscountStoreInfo = ({ gMapsResults }: any) => {
  const allTypes = gMapsResults.map((result: any) => result.types).flat() as string[]
  const allTypesSet = [...new Set(allTypes)]
  return (
    <div>
      <SMP bold black className="subtitle-2" style={{ margin: '18px 0px' }}>
        Sobre el local:
      </SMP>
      <div style={{ display: 'flex' }}>
        <p style={{ marginRight: 10 }}>Categorias:</p>
        {allTypesSet.map((type) => {
          if (googleFoodAndDrinkTypesToMUIIcons[type] !== undefined) {
            return (
              <div
                key={type}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: '8px',
                }}
              >
                {googleFoodAndDrinkTypesToMUIIcons[type]}
              </div>
            )
          } else {
            return (
              <p
                key={type}
                style={{
                  color: neutral50White,
                }}
              >
                {type},{' '}
              </p>
            )
          }
        })}
      </div>
      {gMapsResults[0]?.regularOpeningHours?.weekdayDescriptions && (
        <>
          <p style={{ marginTop: 10 }}>Horarios: </p>
          {gMapsResults[0].regularOpeningHours.weekdayDescriptions.map((day: string) => {
            return (
              <p
                key={gMapsResults[0].queryID + day}
                style={{
                  color: neutral50White,
                }}
              >
                {day}
              </p>
            )
          })}
        </>
      )}
    </div>
  )
}

export const DISCOUNT_DRAWER_BODY_WIDTH = 'min(75vw, 750px)'

const GMapsQueryFrame = ({ discount, query }: { discount: Discount; query: string }) => {
  const [showGMapsResults, setShowGMapsResults] = useState(false)
  const queriesGMapsResultsToShow = discount.gMapsResults
    .filter((result: any) => result.query === query) // buscamos solo los resultados de dicha query
    .map((result: any) => {
      return (
        <div
          key={result.queryID + result.formattedAddress}
          style={{ display: 'flex' }}
          onClick={() => {
            logSMEvent('DISCOUNT_DRAWER_GMAPS_LINK_CLICKED', { id: discount.id })
          }}
        >
          <SMP>-</SMP>
          <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '6px' }}>
            <a
              style={{ color: neutral50White, marginLeft: '8px' }}
              href={result.googleMapsUri}
              target="_blank"
              rel="noreferrer"
            >
              <SMP>{result.formattedAddress || result.formatted_address}</SMP>
            </a>
          </div>
        </div>
      )
    })
  if (queriesGMapsResultsToShow.length === 0) return null
  return (
    <SMHorizontalDiv style={{ alignItems: 'start' }}>
      <LocationOnRoundedIcon fontSize="small" htmlColor={neutral50White} />
      <SMVerticalDiv style={{ gap: 0 }}>
        <SMP>{capitalizeFirstChar(query)}</SMP>
        <div>
          <SMP underline onClick={() => setShowGMapsResults(!showGMapsResults)}>
            {showGMapsResults ? '' : 'Ver resultados de Google Maps'}
          </SMP>
          {showGMapsResults && queriesGMapsResultsToShow}
        </div>
      </SMVerticalDiv>
    </SMHorizontalDiv>
  )
}

export const DiscountDrawer = function ({
  discount,
  isStar,
  childrenDiscounts,
}: {
  discount: Discount | null
  isStar: boolean
  childrenDiscounts: Discount[] | null
}) {
  const { auth, clubs } = useContext(AppContext)
  const { handleOpenReportErrorDrawer, handleDiscountClick } = useContext(DiscountContext)
  const handleOnOpenDiscount = () => {
    // ojo que esta condicion tiene que ser la misma que href en la prop del boton
    if (discount) {
      logSMEvent('DISCOUNT_LINK_CLICKED', {
        id: discount.id,
        url: discount.url,
        userRut: auth?.rut || 'none',
        mainTagV3: discount.mainTagV3,
        club: discount.club,
      })
    }
  }
  if (!discount) return <></>

  const subTarjetaLabel = discount.subTarjeta
    .split('; ')
    .map((stId) => {
      return clubs && clubs[discount.club]?.subTarjetas?.find((st) => st.id == stId)?.label
    })
    .join(', ')
  return (
    <Drawer
      anchor="right"
      open={discount !== null}
      onClose={() => {
        handleDiscountClick(undefined)
      }}
      sx={{ zIndex: 200 }}
    >
      <div
        style={{
          maxWidth: DISCOUNT_DRAWER_BODY_WIDTH,
          backgroundColor: 'white',
          overflowY: 'auto',
          // prevent scroll of elements that are behind
        }}
      >
        <div>
          <div
            className={discountHeaderFrame}
            style={{
              height: '140px',
              borderRadius: 0,
            }}
          >
            <div className={closeCardButton}>
              <CloseRoundedIcon
                sx={{
                  color: 'white',
                  height: '18px',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  return handleDiscountClick(undefined)
                }}
              />
            </div>
            <img // background para que se vea lindo atras de la imagen
              className={backgroundImage}
              src={discount.bgimage}
              alt="."
              referrerPolicy="no-referrer"
            />
            {discount.logo && discount.logo !== discount.bgimage && (
              <img
                style={{
                  objectFit: 'cover',
                }}
                alt="."
                src={discount.logo}
              />
            )}
            <img
              style={{
                marginRight: discount?.gMapsResults ? '2px' : '0px',
                width: '100%',
                objectFit:
                  discount.club == 'Claro' || discount.club == 'Lider Bci' ? 'contain' : 'cover',
              }}
              src={discount?.bgimage}
              alt="."
              referrerPolicy="no-referrer"
            />
            {discount?.gMapsResults
              ?.map((r) => r.photosUrls)
              ?.flat()
              ?.slice(0, 10)
              ?.map((url) => {
                return <img key={url} src={url} alt="." className={gMapPhotoInSlider} />
              })}
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            padding: '14px',
            gap: 14,
          }}
        >
          <SMHorizontalDiv>
            {discount.club && clubs ? (
              <img
                src={clubs[discount.club]?.img}
                style={{ maxWidth: 30, maxHeight: 20, objectFit: 'contain' }}
                alt={discount.club}
              />
            ) : null}
            <SMH3>{discount.titulo}</SMH3>
          </SMHorizontalDiv>
          <DiscountChildrenFrame childrenDiscounts={childrenDiscounts} />
          <SMPrimaryButton
            sx={{
              minWidth: 0, // to override the default minWidth
              minHeight: 0, // to override the default minHeight
              maxWidth: 240,
              maxHeight: 40,
              textAlign: 'start',
              gap: 14,
            }}
            onClick={handleOnOpenDiscount}
            href={addUtmParamsToUrl(discount)}
          >
            <SMP white>Ir al sitio oficial</SMP>
            <OpenInNewRoundedIcon fontSize="small" htmlColor={neutralWhite} />
          </SMPrimaryButton>
          <ShareButton
            discount={discount}
            buttonname="discount_drawer_share_button"
            sx={{
              border: '1px solid',
              borderColor: primaryPrimary,
              color: primaryPrimary,
              backgroundColor: neutralWhite,
              width: 'fit-content',
              height: 'fit-content',
              minWidth: 0, // to override the default minWidth
              minHeight: 0, // to override the default minHeight
              gap: 4,
            }}
          >
            <SMP sx={{ color: primaryPrimary }}>Comparte este descuento</SMP>
            <ShareRoundedIcon fontSize="small" />
          </ShareButton>
          <div>
            <SMHorizontalDiv>
              <SMChip
                onClick={() =>
                  handleOpenReportErrorDrawer({
                    source: 'discount_drawer',
                    discount,
                  })
                }
              >
                <CampaignRoundedIcon sx={{ color: secondary40White }} />
                <p style={{ color: neutralBlack }}>Reportar información incorrecta</p>
              </SMChip>
            </SMHorizontalDiv>
            <SMP small sx={{ marginTop: '4px' }}>
              Revisa los comentarios de otros usuarios más abajo
            </SMP>
          </div>
          <SMP bold black className="subtitle-2" style={{ margin: '18px 0px 8px 0px' }}>
            Información relevante
          </SMP>
          <SMVerticalDiv>
            <div>
              {discount?.gMapsResults?.length > 0 && (
                <Rating mapsResult={discount.gMapsResults[0]} />
              )}
            </div>
            {subTarjetaLabel && (
              <div style={{ display: 'flex', gap: 10 }}>
                <CreditCardRoundedIcon fontSize="small" htmlColor={neutral50White} />
                <SMP>Válido con: {subTarjetaLabel}</SMP>
              </div>
            )}

            <SMHorizontalDiv>
              <CalendarTodayRoundedIcon fontSize="small" htmlColor={neutral50White} />
              <SMP>Válido los días: {normalizeDaysToText(discount.diasNormalizados)}</SMP>
            </SMHorizontalDiv>
            {discount.topeDescuento && (
              <SMHorizontalDiv>
                <PanToolRoundedIcon fontSize="small" htmlColor={neutral50White} />
                <SMP>Tope del descuento: {formatPrice(discount.topeDescuento)}</SMP>
              </SMHorizontalDiv>
            )}
            {discount.fecha_hasta && (
              <p
                style={{
                  backgroundColor: primary90White,
                  padding: '4px 8px',
                  borderRadius: '10px',
                  color: primaryPrimary,
                  width: 'fit-content',
                  // fontWeight: 'bold',
                }}
              >
                Hasta el {discount.fecha_hasta}
              </p>
            )}
            {isStar && <StarOptions discount={discount} />}
            {discount.gMapsResults?.length > 0 && (
              <>
                <div>
                  <SMP bold black className="subtitle-2" style={{ margin: '20px 0px 8px 0px' }}>
                    Direcciones
                  </SMP>
                </div>
                {discount.gMapsQueries.map((query: string) => {
                  return <GMapsQueryFrame discount={discount} key={query} query={query} />
                })}
              </>
            )}
          </SMVerticalDiv>

          {isStar && <SMP>{discount.aiQueryInput}</SMP>}
          <SMP bold black className="subtitle-2" style={{ margin: '18px 0px' }}>
            Descripción
          </SMP>
          <SMVerticalDiv sx={{ gap: 6 }}>
            {capitalizeFirstChar(discount.descripcion)
              // replace any number of one single digit followed by a . for the digit follow by a -
              .replace(/(\d)\./g, '$1-')
              // .split(/(?<!\b(?:dcto|av)|\b\d)\. /i) // no funciona en iOS antiguos
              .split(/(?:dcto|av)?\. /i) // si funciona
              // .split(/(?<![dD]cto)(?<![aA]v)\. /) // no funciona en iOS antiguos. Split on dot not preceded by "dcto" and has a space after it no funciona
              .filter((sentence) => sentence.trim().length > 0) // Filter out empty sentences
              .map((sentence, index) => {
                return (
                  <SMP black key={index}>
                    {'• ' + capitalizeFirstChar(sentence) + '.'}
                  </SMP>
                )
              })}
          </SMVerticalDiv>
          {discount.condiciones && (
            <>
              <SMP bold black className="subtitle-2" style={{ margin: '18px 0px' }}>
                Terminos y condiciones
              </SMP>
              <SMP>{discount.condiciones}</SMP>
            </>
          )}
          {discount.comments?.length && (
            <>
              <SMHorizontalDiv>
                <CampaignRoundedIcon />
                <SMP bold black className="subtitle-2" style={{ margin: '18px 0px 10px' }}>
                  Comentarios
                </SMP>
              </SMHorizontalDiv>
              <SMVerticalDiv>
                {discount.comments
                  .sort((a, b) => b.createdAt._seconds - a.createdAt._seconds)
                  .map((comment, index) => {
                    return (
                      <SMVerticalDiv key={index} sx={{ gap: 4 }}>
                        <SMP bold black className="subtitle-3">
                          {comment.user?.userName || 'Anónimo'}
                        </SMP>
                        <SMP small>
                          {new Intl.DateTimeFormat('es-ES', {
                            day: '2-digit',
                            month: '2-digit',
                            year: 'numeric',
                            hour: '2-digit',
                            minute: '2-digit',
                            hour12: true,
                          }).format(new Date(comment.createdAt._seconds * 1000))}
                        </SMP>
                        <SMP black>{comment.message}</SMP>
                        <Divider sx={{ mt: 1 }} />
                      </SMVerticalDiv>
                    )
                  })}
              </SMVerticalDiv>
              <SMChip
                onClick={() =>
                  handleOpenReportErrorDrawer({
                    source: 'discount_drawer',
                    discount,
                  })
                }
              >
                <CampaignRoundedIcon sx={{ color: secondary40White }} />
                <p style={{ color: neutralBlack }}>Reportar información incorrecta</p>
              </SMChip>
            </>
          )}
          {discount.gMapsResults && isStar && (
            <DiscountStoreInfo gMapsResults={discount.gMapsResults} />
          )}
        </div>
      </div>
    </Drawer>
  )
}
