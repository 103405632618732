import React from 'react'
import { AppBar, Toolbar, Container, Typography, Button, Box, Link } from '@mui/material'
import Grid from '@mui/material/Grid2'
import { primaryPrimary } from '../../../style'

export const SecondLanding = () => {
  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        backgroundColor: primaryPrimary,
      }}
    >
      {/* Header */}
      <AppBar position="static" sx={{ boxShadow: 'none', pt: 2 }}>
        <Toolbar>
          <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
            <img
              src="/path-to-logo.png"
              alt="App Logo"
              style={{ marginRight: 8, width: 40, height: 40 }}
            />
            <h2>SaveMoney</h2>
          </Box>
          <Typography variant="body1">by wayoalamos</Typography>
        </Toolbar>
      </AppBar>

      {/* Main Section */}
      <Container
        maxWidth="lg"
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          justifyContent: 'space-between',
          alignItems: 'center',
          py: 4,
        }}
      >
        <Box sx={{ textAlign: { xs: 'center', md: 'left' }, mb: { xs: 4, md: 0 } }}>
          <Typography variant="h3">Aprovecha tus descuentos</Typography>
          <Typography variant="body1" gutterBottom>
            La aplicación que reúne los descuentos de: tus tarjetas bancarias, tus tarjetas vecinos,
            tu compañía de celular, tus billeteras digitales y más.
          </Typography>
          <Button variant="contained" color="primary" sx={{ mt: 2, mb: 1 }}>
            Prueba la versión web
          </Button>
          <Typography sx={{ fontSize: '0.75rem', mt: 1 }}>
            Al continuar aceptas los términos y condiciones
          </Typography>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              width: '300px',
              height: '600px',
              background: '#ccc',
              borderRadius: '30px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img src="/path-to-app-screenshot.png" alt="App Screenshot" style={{ width: '90%' }} />
          </Box>
          <Box sx={{ mt: 2, display: 'flex', gap: 2 }}>
            <Button variant="outlined" color="primary">
              App Store
            </Button>
            <Button variant="outlined" color="primary">
              Play Store
            </Button>
          </Box>
        </Box>
      </Container>

      {/* Placeholder for other sections */}
      <Container>{/* Add your app explanation sections here */}</Container>

      {/* Follow Us Section */}
      <Box sx={{ textAlign: 'center', p: 4 }}>
        <Typography variant="body1">Síguenos</Typography>
        <Grid container justifyContent="center" spacing={2}>
          <Grid>
            <Link href="https://www.facebook.com" target="_blank" rel="noopener">
              Facebook
            </Link>
          </Grid>
          <Grid>
            <Link href="https://www.instagram.com" target="_blank" rel="noopener">
              Instagram
            </Link>
          </Grid>
          <Grid>
            <Link href="https://www.twitter.com" target="_blank" rel="noopener">
              Twitter
            </Link>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}
